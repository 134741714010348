import React, { useState, useEffect } from 'react';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Spin,
	Table,
	Tag,
	Row,
	Col,
	Input,
	Button,
	Popconfirm,
	Avatar,
	List,
} from 'antd';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './process-config-table.scss';
import { withRouter, useHistory, Link } from 'react-router-dom';

const SettingTable = () => {
	let history = useHistory();
	const [filteredData, setFilteredData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchCriteria, setSearchCriteria] = useState({});
	const currentUser = useSelector((state) => state.auth.currentUser);

	useEffect(() => {
		let isMounted = true;
		if (currentUser && currentUser.id && isMounted) {
			fetchTable();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		applySearchCriteria();
	}, [searchCriteria]);

	const applySearchCriteria = () => {
		let filterData = tableData;
		if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
			filterData = filterData.filter(
				(row) =>
					row.clientName
						.toLowerCase()
						.indexOf(searchCriteria.keyword.toLowerCase()) > -1
			);
			setFilteredData(filterData);
		}
		setFilteredData(filterData);
	};

	const filterByKeyword = (e) => {
		delay(function () {
			setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
		}, 500);
	};

	const fetchTable = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/rpa/process-configs`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const data = response.data.data.map((res, index) => {
					index++;
					return {
						key: res.id,
						no: res.id,
						companyName: res.company?.name,
						departmentName: res.department?.name,
						processName: res.processName,
						triggerName: res.triggerName,
						customSettings: res.customSettings,
						variables: res.variables,
						created: res.created,
						createdBy: res.createdBy,
					};
				});
				console.log('Table Data', data);
				setTableData(data);
				setFilteredData(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	const deleteSetting = async (settingId) => {
		await axiosConfig({
			method: 'delete',
			url: `/api/roxuslogging/clients/setting/${settingId}`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				fetchTable();
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

	const changePage = (page, settingId) => {
		switch (page) {
			case 'create':
				history.push('/rpa/create-process-config');
				break;
			case 'edit':
				history.push(`/rpa/edit-process-config/${settingId}`);
				break;
			default:
				break;
		}
	};

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const columns = [
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			render: (text, record) => {
				return (
					<>
						<Button onClick={() => changePage('edit', record.key)}>
							Edit
						</Button>
						<Popconfirm
							placement='top'
							title={'Are you sure to delete this setting?'}
							onConfirm={() => deleteSetting(record.key)}
							okText='Yes'
							cancelText='No'
						>
							<Button type='danger'>Delete</Button>
						</Popconfirm>
					</>
				);
			},
		},
		{
			title: 'Company Name',
			dataIndex: 'companyName',
			key: 'companyName',
		},
		{
			title: 'Department Name',
			dataIndex: 'departmentName',
			key: 'departmentName',
		},
		{
			title: 'Process Name',
			dataIndex: 'processName',
			key: 'processName',
		},
		{
			title: 'Trigger Name',
			dataIndex: 'triggerName',
			key: 'triggerName',
		},
		{
			title: 'Setting Detail',
			dataIndex: 'customSettings',
			key: 'customSettings',
			renderItem: (item, index) => {
				let customSettingsStr = 'customSettings';
				let customSettings = JSON.parse(customSettingsStr);
				for (const [key, value] of Object.entries(customSettings)) {
					console.log(`${key}: ${value}`);
					<List.Item>
						<List.Item.Meta
							avatar={
								<Avatar src='https://joeschmoe.io/api/v1/random' />
							}
							title={
								<a href='https://ant.design'>{item.title}</a>
							}
							description='Ant Design, a design language for background applications, is refined by Ant UED Team'
						/>
					</List.Item>;
				}
			},
		},
		{
			title: 'Variables',
			dataIndex: 'variables',
			key: 'variables',
			renderItem: (item, index) => {
				let variablesStr = 'variables';
				let variables = JSON.parse(variablesStr);
				for (const [key, value] of Object.entries(variables)) {
					console.log(`${key}: ${value}`);
					<List.Item>
						<List.Item.Meta
							avatar={
								<Avatar src='https://joeschmoe.io/api/v1/random' />
							}
							title={
								<a href='https://ant.design'>{item.title}</a>
							}
							description='Ant Design, a design language for background applications, is refined by Ant UED Team'
						/>
					</List.Item>;
				}
			},
		},
		{
			title: 'Created Time',
			key: 'created',
			dataIndex: 'created',
		},
		{
			title: 'Modified Time',
			key: 'modified',
			dataIndex: 'modified',
		},
	];

	return (
		<Spin spinning={loading}>
			<Row className='search-area' gutter={{ lg: 8 }}>
				<Col span={8}>
					<Button onClick={() => changePage('create')} type='primary'>
						Create
					</Button>
				</Col>
				<Col span={8} offset={8}>
					<Input
						id='search-box'
						onKeyUp={filterByKeyword}
						addonBefore={<SearchOutlined />}
						placeholder='Search'
					/>
				</Col>
			</Row>
			<Table columns={columns} dataSource={filteredData} style={{ width: '100%' }}/>
		</Spin>
	);
};

export default SettingTable;
