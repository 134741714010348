import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import './edit-process-config.scss';

const UpdateSetting = () => {
	const params = useParams();
	let history = useHistory();
	const [loading, setLoading] = useState(true);
	const [companyData, setCompanyData] = useState([]);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const [currentConfig, setCurrentConfig] = useState({});
	const tailLayout = {
		wrapperCol: {
			offset: 4,
			span: 12,
		},
	};

	const [form] = Form.useForm();

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const fetchProcessConfig = async () => {
		console.log(params);
		return await axiosConfig({
			method: 'get',
			url: `/api/rpa/process-configs/${params.settingId}`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				let setting = response.data.data;
				console.log('Setting', setting);
				let customSettingsStr = setting.customSettings;
				let variablesStr = setting.variables;
				let editConfig = {};
				editConfig.CompanyId = setting.company?.id;

				if (editConfig.CompanyId) {
					handleCompanyChange(editConfig.CompanyId);
				}
				editConfig.CompanyName = setting.company?.name;
				editConfig.DepartmentId = setting.department?.id;
				editConfig.DepartmentName = setting.department?.name;
				editConfig.ProcessName = setting.processName;
				editConfig.TriggerName = setting.triggerName;

				if (customSettingsStr && isJsonString(customSettingsStr)) {
					let configs = [];
					let customSettings = JSON.parse(customSettingsStr);
					for (const [key, value] of Object.entries(customSettings)) {
						console.log(`${key}: ${value}`);
						let config = {};
						config.Name = value.Name;
						config.Value = value.Value;
						configs.push(config);
					}
					editConfig.CustomSettings = configs;
				}

				if (variablesStr && isJsonString(variablesStr)) {
					let variables = [];
					let variablesJson = JSON.parse(variablesStr);
					for (const [key, value] of Object.entries(variablesJson)) {
						console.log("Key Value", `${key}: ${value}`);
						let variable = {};
						variable.Name = value.Name;
						variables.push(variable);
					}
					editConfig.Variables = variables;
				}
				console.log('Current Configs', editConfig);
				setCurrentConfig(editConfig);
				form.setFieldsValue(editConfig);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

	const onFinish = async (values) => {

		console.log(JSON.stringify(values));
		if (values.CustomSettings) {
			values.CustomSettings = JSON.stringify(values.CustomSettings);
		}
		if (values.Variables) {
			values.Variables = JSON.stringify(values.Variables);
		}

		await axiosConfig({
			method: 'put',
			data: values,
			url: `/api/rpa/process-configs/${params.settingId}`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				history.push('/rpa/process-config');
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

	const fetchCompanies = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/settings/companies`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const companyOptions = response.data.data.map((company, index) => {
					var companyId = company.id;
					var companyName = company.name;
					return {
						value: companyId,
						label: companyName,
					}
				});
				const companyData = response.data.data;
				setCompanyData(companyData);
				setCompanyOptions(companyOptions);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});

	}

	const handleCompanyChange = async (e) => {
		console.log("Company", e);
		console.log("Company Data", companyData);
		for (let i = 0; i < companyData.length; i++) {
			let company = companyData[i];
			let companyId = company.id;
			if (companyId == e) {
				console.log("Found Company", company);
				let companyDepartments = company.departments;
				let departmentOptions = companyDepartments.map(d => {
					let option = { "value": d.id, "label": d.name }
					return option;
				});
				setDepartmentOptions(departmentOptions);
			}
		}
	}

	const onReset = () => {
		form.resetFields();
	};

	const changePage = (page) => {
		switch (page) {
			case 'process-config':
				history.push('/rpa/process-config');
				break;
			default:
				break;
		}
	};

	useEffect(async () => {
		document.title = 'Roxus | Edit Process Config';
		await fetchCompanies();
		await fetchProcessConfig();
	}, []);

	return (
		<>
			<div className='ocr-list-page'>
				<div className='ocr-title'>
					<span className='text'>EDIT PROCESS CONFIG</span>
				</div>
			</div>
			<Form
				labelCol={{
					span: 4,
				}}
				wrapperCol={{
					span: 16,
				}}
				form={form}
				name='dynamic_form_complex'
				onFinish={onFinish}
				autoComplete='off'
				key={currentConfig}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<>
						<Form.Item
							className=''
							colon={false}
							name='CompanyId'
							label='Company'>
							<Select
								placeholder="Select Company"
								onChange={handleCompanyChange}
								options={companyOptions}
							/>
						</Form.Item>
						<Form.Item
							className=''
							colon={false}
							name='DepartmentId'
							label='Department'>
							<Select
								placeholder="Select Department"
								// onChange={handleDepartmentChange}
								// defaultValue={[generalDepartmentId]}
								options={departmentOptions}
							/>
						</Form.Item>
						<Form.Item
							label='Process Name'
							name='ProcessName'
							rules={[
								{
									required: true,
									message: 'Process Name is required',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item label='Wizard Custom Id' name='TriggerName' rules={[
							{
								required: true,
								message: 'Wizard Custom Id is required',
							},
						]}>
							<Input />
						</Form.Item>
						<Form.Item label='Configs'>
							<Form.List name={'CustomSettings'}>
								{(subFields, subOpt) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										{subFields.map((subField, index) => (
											<Space
												key={subField.key}
												style={{
													alignItems: 'unset',
												}}
											>
												<Form.Item
													name={[
														subField.name,
														'Name',
													]}
												>
													<Input
														placeholder={`Config ${index + 1
															}`}
													/>
												</Form.Item>
												<Form.Item
													name={[
														subField.name,
														'Value',
													]}
													style={{
														width: '600px',
													}}
												>
													<Input
														placeholder={`Value ${index + 1
															}`}
													/>
												</Form.Item>
												<CloseOutlined
													onClick={() => {
														subOpt.remove(
															subField.name
														);
													}}
												/>
											</Space>
										))}
										<Button
											type='dashed'
											onClick={() => subOpt.add()}
											block
										>
											+ Add New Config
										</Button>
									</div>
								)}
							</Form.List>
						</Form.Item>
						<Form.Item label='Variables'>
							<Form.List name={'Variables'}>
								{(subFields, subOpt) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										{subFields.map((subField, index) => (
											<Space
												key={subField.key}
												style={{
													alignItems: 'unset',
												}}
											>
												<Form.Item
													name={[
														subField.name,
														'Name',
													]}
												>
													<Input
														placeholder={`Variable ${index + 1
															}`}
													/>
												</Form.Item>
												<CloseOutlined
													onClick={() => {
														subOpt.remove(
															subField.name
														);
													}}
												/>
											</Space>
										))}
										<Button
											type='dashed'
											onClick={() => subOpt.add()}
											block
										>
											+ Add New Variable
										</Button>
									</div>
								)}
							</Form.List>
						</Form.Item>
					</>
				</div>
				<Form.Item {...tailLayout}>
					<Button type='primary' htmlType='submit'>
						Save
					</Button>
					<Button htmlType='button' onClick={onReset}>
						Clear
					</Button>
					<Button
						htmlType='button'
						onClick={() => changePage('process-config')}
					>
						Don't Save
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default UpdateSetting;
