import React, { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import {
	Button,
	Select,
	Card,
	Form,
	Input,
	Space,
	Typography,
	message,
	notification,
} from 'antd';
import { withRouter, useHistory, Link } from 'react-router-dom';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import './create-process-config.scss';

const CreateProcessConfig = () => {

	let history = useHistory();
	const tailLayout = {
		wrapperCol: {
			offset: 4,
			span: 12,
		},
	};
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(true);
	const [companyData, setCompanyData] = useState([]);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const delay = (ms) => new Promise((res) => setTimeout(res, ms));
	const openNotificationWithIcon = (type, errorDetail) => {
		switch (type) {
			case 'success':
				notification[type]({
					message: 'Create Process Config SUCCESSFULLY',
				});

				break;
			case 'error':
				notification[type]({
					message: 'Create Process Config FAILED',
					description: errorDetail,
				});
				break;
			default:
				break;
		}
	};

	const onFinish = async (values) => {

		
		if (values.CustomSettings) {
			values.CustomSettings = JSON.stringify(values.CustomSettings);
		}
		if (values.Variables) {
			values.Variables = JSON.stringify(values.Variables);
		}

		console.log(JSON.stringify(values));
		await axiosConfig({
			method: 'post',
			data: values,
			url: `/api/rpa/process-configs`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
				'Content-Type': 'application/json'
			},
		})
			.then(async (response) => {
				console.log(response);
				openNotificationWithIcon('success');
				await delay(3000);
				history.push('/rpa/process-config');
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
				openNotificationWithIcon('error', error.response.data);
			});
	};

	const onReset = () => {
		form.resetFields();
	};

	const fetchCompanies = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/settings/companies`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				const companyOptions = response.data.data.map((company, index) => {
					var companyId = company.id;
					var companyName = company.name;
					return {
						value: companyId,
						label: companyName,
					}
				});
				const companyData = response.data.data;
				setCompanyData(companyData);
				setCompanyOptions(companyOptions);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});

	}

	const handleCompanyChange = async(e) => {
		console.log("Company", e);

		console.log("Company Data", companyData);
		for (let i = 0; i < companyData.length; i++) {
			let company = companyData[i];
		    let companyId = company.id;
			if (companyId == e)
			{
				console.log("Found Company", company);
				let companyDepartments = company.departments;
				let departmentOptions = companyDepartments.map(d => {
					let option = {"value": d.id, "label": d.name}
					return option;
				});
				setDepartmentOptions(departmentOptions);
			}
		}
	}

	const changePage = (page) => {
		switch (page) {
			case 'process-config':
				history.push('/rpa/process-config');
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		document.title = 'Roxus | Create Setting';
		fetchCompanies();
	}, []);

	return (
		<>
			<div className='ocr-list-page'>
				<div className='ocr-title'>
					<span className='text'>CREATE PROCESS CONFIG</span>
				</div>
			</div>
			<Form
				labelCol={{
					span: 4,
				}}
				wrapperCol={{
					span: 16,
				}}
				form={form}
				name='dynamic_form_complex'
				onFinish={onFinish}
				autoComplete='off'
				initialValues={{
					items: [{}],
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<>
						<Form.Item
							className=''
							colon={false}
							name='CompanyId'
							label='Company'>
							<Select
								placeholder="Select Company"
								onChange={handleCompanyChange}
								options={companyOptions}
							/>
						</Form.Item>
						<Form.Item
							className=''
							colon={false}
							name='DepartmentId'
							label='Department'>
							<Select
								placeholder="Select Department"
							// onChange={handleDepartmentChange}
							// defaultValue={[generalDepartmentId]}
							   options={departmentOptions}
							/>
						</Form.Item>
						<Form.Item
							label='Process Name'
							name='ProcessName'
							rules={[
								{
									required: true,
									message: 'Process Name is required',
								},
							]}
						>
							<Input />
						</Form.Item>
						<Form.Item label='Wizard Custom Id' name='TriggerName' rules={[
							{
								required: true,
								message: 'Wizard Custom Id is required',
							},
						]}>
							<Input />
						</Form.Item>
						{/* Nest Form.List */}
						<Form.Item label='Configs'>
							<Form.List name={'CustomSettings'}>
								{(subFields, subOpt) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										{subFields.map((subField, index) => (
											<Space
												key={subField.key}
												style={{
													alignItems: 'unset',
												}}
											>
												<Form.Item
													name={[
														subField.name,
														'Name',
													]}
												>
													<Input
														placeholder={`Config ${index + 1
															}`}
													/>
												</Form.Item>
												<Form.Item
													name={[
														subField.name,
														'Value',
													]}
													style={{
														width: '600px',
													}}
												>
													<Input
														placeholder={`Value ${index + 1
															}`}
													/>
												</Form.Item>
												<CloseOutlined
													style={{ color: '#ff4d4f' }}
													onClick={() => {
														subOpt.remove(
															subField.name
														);
													}}
												/>
											</Space>
										))}
										<Button
											type='dashed'
											onClick={() => subOpt.add()}
											block
										>
											+ Add New Config
										</Button>
									</div>
								)}
							</Form.List>
						</Form.Item>
						<Form.Item label='Variables'>
							<Form.List name={'Variables'}>
								{(subFields, subOpt) => (
									<div
										style={{
											display: 'flex',
											flexDirection: 'column',
										}}
									>
										{subFields.map((subField, index) => (
											<Space
												key={subField.key}
												style={{
													alignItems: 'unset',
												}}
											>
												<Form.Item
													name={[
														subField.name,
														'Name',
													]}
												>
													<Input
														placeholder={`Variable ${index + 1
															}`}
													/>
												</Form.Item>
												<CloseOutlined
													style={{ color: '#ff4d4f' }}
													onClick={() => {
														subOpt.remove(
															subField.name
														);
													}}
												/>
											</Space>
										))}
										<Button
											type='dashed'
											onClick={() => subOpt.add()}
											block
										>
											+ Add New Variable
										</Button>
									</div>
								)}
							</Form.List>
						</Form.Item>
					</>
				</div>
				<Form.Item {...tailLayout}>
					<Button type='primary' htmlType='submit'>
						Save
					</Button>
					<Button htmlType='button' onClick={onReset}>
						Clear
					</Button>
					<Button
						htmlType='button'
						onClick={() => changePage('process-config')}
					>
						Don't Save
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export default CreateProcessConfig;
