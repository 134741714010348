import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Spin,
	Table,
	Tag,
	Row,
	Col,
	Input,
	Button,
	Popconfirm,
	Avatar,
	List,
	DatePicker,
	Select,
	message,
} from 'antd';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './main-logging-table.scss';
import { withRouter, useHistory, Link } from 'react-router-dom';

const MainLoggingTable = () => {
	let history = useHistory();
	const [messageApi, contextHolder] = message.useMessage();
	const [filterParams, setFilterParams] = useState({});
	const [customers, setCustomers] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [processDisable, setProcessDisable] = useState(true);
	const [processes, setProcesses] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchCriteria, setSearchCriteria] = useState({});
	const currentUser = useSelector((state) => state.auth.currentUser);

	useEffect(() => {
		let isMounted = true;
		if (currentUser && currentUser.id && isMounted) {
			// fetchTable();
			fetchCustomers();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	useEffect(() => {
		fetchCompanies();
		fetchTable();
		applySearchCriteria();
	}, [searchCriteria]);

	const applySearchCriteria = () => {
		let filterData = tableData;
		if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
			filterData = filterData.filter(
				(row) =>
					(row.companyName &&
						row.companyName
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) >
						-1) ||
					(row.projectName &&
						row.projectName
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) >
						-1) ||
					(row.processName &&
						row.processName
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) > -1)
			);
			setFilteredData(filterData);
		}
		setFilteredData(filterData);
	};

	const filterByKeyword = (e) => {
		delay(function () {
			setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
		}, 500);
	};

	const fetchCompanies = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/rpa/main-loggings/companies`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log("Company Data", response);
				let companyList = response.data.data;
				let newCompanies = companyList.map((c) => {
					return { value: c.id, label: c.name };
				});
				setCompanies(newCompanies);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				console.log(error.config);
			});
	};

	const fetchTable = async () => {

		try {
			setLoading(true);
			await axiosConfig({
				method: 'get',
				url: `/api/rpa/main-loggings/today`,
				headers: {
					Authorization: `Bearer ${cookies.get('access_token')}`,
				},
			})
				.then((response) => {
					console.log('Table Data', response);
					const data = response.data.data.map((res, index) => {
						index++;
						return {
							key: res.id,
							no: res.id,
							companyName: res.company?.name,
							departmentName: res.department?.name,
							processName: res.processName,
							initiatedBy: res.initiatedBy,
							techStatus: res.techStatus,
							businessStatus: res.businessStatus,
							oSUserName: res.oSUserName,
							userName: res.userName,
							startComments: res.startComments,
							endComments: res.endComments,
							startTime: res.startTime,
							endTime: res.endTime,
						};
					});
					setTableData(data);
					setFilteredData(data);
					setLoading(false);
				})
				.catch((error) => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					setLoading(false);
					console.log(error.config);
				})
		}
		catch (error) {

		}
		finally {
			setLoading(false);
		}

	};

	const fetchQueryTable = async (request) => {

		try {

			setLoading(true);
			await axiosConfig({
				method: 'post',
				data: request,
				url: `/api/rpa/main-loggings/query`,
				headers: {
					Authorization: `Bearer ${cookies.get('access_token')}`,
				},
			})
				.then((response) => {
					const data = response.data.data.map((res, index) => {
						index++;
						return {
							key: res.id,
							no: res.id,
							companyName: res.company?.name,
							departmentName: res.department?.name,
							processName: res.processName,
							initiatedBy: res.initiatedBy,
							techStatus: res.techStatus,
							businessStatus: res.businessStatus,
							oSUserName: res.oSUserName,
							userName: res.userName,
							startComments: res.startComments,
							endComments: res.endComments,
							startTime: res.startTime,
							endTime: res.endTime,
						};
					});
					console.log('Table Data', data);
					setTableData(data);
					setFilteredData(data);
					setLoading(false);
				})
				.catch((error) => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					setLoading(false);
					console.log(error.config);
				});
		}
		catch (error) {

		}
		finally {
			setLoading(false);
		}


	};

	const fetchCustomers = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/roxuslogging/logging-customers`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				console.log(response);
				let customerList = response.data;
				let newCustomers = customerList.map((c) => {
					return { value: c, label: c };
				});
				setCustomers(newCustomers);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	const fetchProcessesByCompany = async (request) => {

		var companyId = request.companyName;

		if (companyId) {
			await axiosConfig({
				method: 'get',
				url: `/api/rpa/main-loggings/companies/${companyId}/processNames`,
				data: request,
				headers: {
					Authorization: `Bearer ${cookies.get('access_token')}`,
				},
			})
				.then((response) => {
					console.log(response);
					let processList = response.data.data;
					let newProcesses = processList.map((p) => {
						if (p) {
							return { value: p, label: p };
						}
					});
					if (newProcesses.length > 0) {
						setProcesses(newProcesses);
						setProcessDisable(false);
					}
					else {
						setProcesses([]);
						setProcessDisable(true);
					}

				})
				.catch((error) => {
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						console.log(error.response.data);
						console.log(error.response.status);
						console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log('Error', error.message);
					}
					setLoading(false);
					console.log(error.config);
				});
		}

	};

	const changePage = (page, logId) => {
		switch (page) {
			case 'main-logging':
				window.open(`/rpa/main-logging/${logId}`, '_blank');
				break;
			default:
				break;
		}
	};

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const columns = [
		{
			title: 'Action',
			key: 'action',
			dataIndex: 'action',
			render: (text, record) => {
				return (
					<>
						<Button
							onClick={() =>
								changePage('main-logging', record.key)
							}
						>
							View Log
						</Button>
					</>
				);
			},
		},
		{
			title: 'Start Time',
			key: 'startTime',
			dataIndex: 'startTime',
			render: (text) => {
				const dateObject = moment(text);
				const formattedDate = dateObject.format("DD MMM, YYYY hh:mm A");
				return formattedDate;
			}
		
		},
		{
			title: 'End Time',
			key: 'endTime',
			dataIndex: 'endTime',
			render: (text) => {
				if (text)
				{
					const dateObject = moment(text);
					const formattedDate = dateObject.format("DD MMM, YYYY hh:mm A");
					return formattedDate;
				}
			}
		},
		// {
		// 	title: 'Id',
		// 	dataIndex: 'no',
		// 	key: 'no',
		// },
		{
			title: 'Company Name',
			dataIndex: 'companyName',
			key: 'companyName',
		},
		{
			title: 'Department Name',
			dataIndex: 'departmentName',
			key: 'departmentName',
		},
		{
			title: 'Process Name',
			dataIndex: 'processName',
			key: 'processName',
		},
		{
			title: 'Initiated By',
			dataIndex: 'initiatedBy',
			key: 'initiatedBy',
		},
		{
			title: 'Tech Status',
			key: 'techStatus',
			dataIndex: 'techStatus',
			render: (text) => {
				if (text == "1") {
					return <Tag color="default">No Status</Tag>;
				}
				else if (text == "2") {
					return <Tag color="success">Successful</Tag>;
				}
				else if (text == "3") {
					return <Tag color="error">Unsuccessful</Tag>;
				}
				else if (text == "4") {
					return <Tag color="error">Failed</Tag>;
				}
				else {
					return <Tag color="default">No Status</Tag>;
				}
			},
		},
		{
			title: 'Business Status',
			key: 'businessStatus',
			dataIndex: 'businessStatus',
			render: (text) => {
				if (text == "1") {
					return <Tag color="default">No Status</Tag>;
				}
				else if (text == "2") {
					return <Tag color="success">Successful</Tag>;
				}
				else if (text == "3") {
					return <Tag color="error">Unsuccessful</Tag>;
				}
				else if (text == "4") {
					return <Tag color="error">Failed</Tag>;
				}
				else {
					return <Tag color="default">No Status</Tag>;
				}
			},
		},
		// {
		// 	title: 'OS Username',
		// 	key: 'oSUserName',
		// 	dataIndex: 'oSUserName',
		// },
		// {
		// 	title: 'Username',
		// 	key: 'userName',
		// 	dataIndex: 'userName',
		// },
		{
			title: 'Start Comments',
			key: 'startComments',
			dataIndex: 'startComments',
		},
		{
			title: 'End Comments',
			key: 'endComments',
			dataIndex: 'endComments',
		}
	];

	const onCompanyChange = async (value) => {
		console.log(`selected ${value}`);
		setFilterParams({ ...filterParams, companyName: value });
		if (value) {
			let processRequest = { companyName: value };
			await fetchProcessesByCompany(processRequest);
		} else {
			setProcessDisable(true);
		}
	};

	const onCompanySearch = (value) => {
		console.log('search:', value);
	};

	const onProcessChange = (value) => {
		console.log(`selected ${value}`);
		setFilterParams({ ...filterParams, processName: value });
	};

	const onProcessSearch = (value) => {
		console.log('Process search:', value);
	};

	const onDateChange = (date, dateString) => {
		console.log(dateString);
		setFilterParams({ ...filterParams, date: dateString });
	};

	const filterProcesses = async () => {

		console.log('Filter Params', filterParams);

		await fetchQueryTable(filterParams);

	};

	const error = (errorContent) => {
		if (!errorContent) {
			errorContent = 'This is an error message';
		}
		messageApi.open({
			type: 'error',
			content: errorContent,
		});
	};

	// Filter `option.label` match the user type `input`
	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	return (
		<>
			{contextHolder}
			<Spin spinning={loading}>
				<Row className='search-area' gutter={{ lg: 8 }}>
					<Col span={16}>
						<Select
							showSearch
							placeholder='Select Company'
							optionFilterProp='children'
							onChange={onCompanyChange}
							onSearch={onCompanySearch}
							filterOption={filterOption}
							options={companies}
							style={{ width: '250px' }}
						/>
						<Select
							showSearch
							disabled={processDisable}
							placeholder='Select Process'
							optionFilterProp='children'
							onChange={onProcessChange}
							onSearch={onProcessSearch}
							filterOption={filterOption}
							options={processes}
							style={{ width: '250px' }}
						/>
						<DatePicker
							placeholder='Select Date'
							onChange={onDateChange}
							style={{ width: '150px' }}
						/>
						<Button
							type='primary'
							onClick={filterProcesses}
							icon={<SearchOutlined />}
						>
							Search
						</Button>
					</Col>
					<Col span={8}>
						<Input
							id='search-box'
							onKeyUp={filterByKeyword}
							addonBefore={<SearchOutlined />}
							placeholder='Search'
						/>
					</Col>
				</Row>
				<Table loading={loading} columns={columns} scroll={{ x: '800px' }} dataSource={filteredData} />
			</Spin>
		</>
	);
};

export default MainLoggingTable;
