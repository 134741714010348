import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FileSearchOutlined, SearchOutlined } from '@ant-design/icons';
import { Spin, Table, Row, Col, Input, Button, Drawer } from 'antd';
import { CSVLink } from 'react-csv';
import { delay } from '../../helpers/helpers';
import cookies from 'js-cookie';
import axiosConfig from '../../utils/axios-config';
import { useSelector } from 'react-redux';
import './main-logging-table.scss';
import { withRouter, useHistory, Link, useParams } from 'react-router-dom';

const LoggingDetailsTable = () => {
	const params = useParams();
	let history = useHistory();
	const [open, setOpen] = useState(false);
	const [filteredData, setFilteredData] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchCriteria, setSearchCriteria] = useState({});
	const [longEntries, setLongEntries] = useState({});
	const [currentEntry, setCurrentEntry] = useState('');
	const currentUser = useSelector((state) => state.auth.currentUser);

	useEffect(() => {
		let isMounted = true;
		if (currentUser && currentUser.id && isMounted) {
			fetchTable();
		}
		return () => {
			isMounted = false;
		};
	}, []);

	const showDrawer = () => {
		setOpen(true);
	};

	const onClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		applySearchCriteria();
	}, [searchCriteria]);

	const applySearchCriteria = () => {
		let filterData = tableData;
		if (searchCriteria.keyword && searchCriteria.keyword.length >= 3) {
			console.log(filterData);
			filterData = filterData.filter(
				(row) =>
					(row.entryData &&
						row.entryData.toString()
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) >
						-1) ||
					(row.sowName &&
						row.sowName.toString()
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) >
						-1) ||
					(row.sowStepName &&
						row.sowStepName.toString()
							.toLowerCase()
							.indexOf(searchCriteria.keyword.toLowerCase()) > -1)
			);
			setFilteredData(filterData);
		}
		setFilteredData(filterData);
	};

	const filterByKeyword = (e) => {
		delay(function () {
			setSearchCriteria({ ...searchCriteria, keyword: e.target.value });
		}, 500);
	};

	const showFullEntry = (index) => {
		console.log(longEntries[index]);

		setCurrentEntry(longEntries[index]);
		setOpen(true);
	};

	const changePage = (page, logId) => {
		switch (page) {
			case 'main-logging':
				history.push(`/rpa/main-logging`);
				break;
			default:
				break;
		}
	};

	const fetchTable = async () => {
		await axiosConfig({
			method: 'get',
			url: `/api/rpa/main-loggings/${params.loggingId}/log-entries`,
			headers: {
				Authorization: `Bearer ${cookies.get('access_token')}`,
			},
		})
			.then((response) => {
				// console.log(response);
				const data = response.data.data.map((res, index) => {
					index++;
					let entryData = res.entryData;
					let minEntryData = '';
					let minEntry = false;

					if (entryData.length > 100) {
						minEntry = true;
						let currentLong = longEntries;
						currentLong[index] = entryData;
						setLongEntries(currentLong);
						minEntryData = (
							<>
								<div style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}>{entryData.substring(0, 100)}</div>
								<Button
									type='link'
									onClick={() => showFullEntry(index)}
								>
									Show more
								</Button>
							</>
						);
					}

					return {
						key: res.id,
						no: index,
						entryData: minEntry ? minEntryData : entryData,
						sowName: res.sowName,
						sowStepNumber: res.sowStepNumber,
						sowStepName: res.sowStepName,
						time: res.time,
					};
				});
				// console.log('Table Data', data);
				setTableData(data);
				setFilteredData(data);
				setLoading(false);
			})
			.catch((error) => {
				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(error.response.data);
					console.log(error.response.status);
					console.log(error.response.headers);
				} else if (error.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log('Error', error.message);
				}
				setLoading(false);
				console.log(error.config);
			});
	};

	function isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	const columns = [
		{
			title: 'Id',
			dataIndex: 'no',
			key: 'no',
		},
		{
			title: 'Entry Data',
			dataIndex: 'entryData',
			key: 'entryData',
		},
		{
			title: 'Sow Name',
			dataIndex: 'sowName',
			key: 'sowName',
		},
		{
			title: 'Sow Step Number',
			dataIndex: 'sowStepNumber',
			key: 'sowStepNumber',
		},
		{
			title: 'Sow Step Name',
			dataIndex: 'sowStepName',
			key: 'sowStepName',
		},
		{
			title: 'Time',
			dataIndex: 'time',
			key: 'time',
			render: (text) => {
				if (text) {
					const dateObject = moment(text);
					const formattedDate = dateObject.format("DD MMM, YYYY hh:mm:ss A");
					return formattedDate;
				}
			}
		},
	];

	return (
		<>
			<Spin spinning={loading}>
				<Row className='search-area' gutter={{ lg: 8 }}>
					<Col span={8}>
						<Button
							onClick={() => changePage('main-logging')}
							type='primary'
						>
							Back to List
						</Button>
					</Col>
					<Col span={8} offset={8}>
						<Input
							id='search-box'
							onKeyUp={filterByKeyword}
							addonBefore={<SearchOutlined />}
							placeholder='Search'
						/>
					</Col>
				</Row>
				<CSVLink
					filename={'LoggingContent.csv'}
					data={tableData}
					className='btn btn-primary'
				>
					Download csv
				</CSVLink>
				<Table columns={columns} dataSource={filteredData} style={{ tableLayout: 'fixed', width: '100%' }} pagination={{ defaultPageSize: 100 }} />
			</Spin>
			<Drawer
				title='Entry Data'
				placement={'bottom'}
				closable={true}
				onClose={onClose}
				size='large'
				getContainer={false}
				open={open}
				visible={open}
			>
				{currentEntry}
			</Drawer>
		</>
	);
};

export default LoggingDetailsTable;
